<template lang="pug">
     v-container
        template(v-if="selectedProject")
            v-card.pointer(v-for="network in networks" :key="network.id" class="mx-auto mb-1 pointer" max-width="400" @click.native="goToNetwork(network)")
                v-card-title {{ network.name }}

        v-card(v-if="loading" class="mx-auto" flat max-width="50")
            v-progress-circular(:size="50" color="primary" indeterminate )

        v-card(v-if="error" class="mx-auto" max-width="400")
            v-card-title Что-то пошло не по плану
                v-spacer
                v-icon(color="red") mdi-alert
            v-card-subtitle {{ error }}



</template>


<script>
    import { projectService, networkService } from '@/_services'
    import { mapMutations, mapState } from "vuex";

    export default {
        data: () => ({
            loading:false,
            error: null,
            networks:[]
        }),

        mounted() {
            if (!this.selectedProject) this.loadProject()
            else {
                this.setAppBarTitle(this.selectedProject.name)
            }
            this.loadNetworks()
        },
        destroyed() {
            this.setSelectedProject(null)
        },

        computed: {
            ...mapState("project", ["selectedProject"])
        },
        methods: {
            ...mapMutations(['setAppBarTitle']),
            ...mapMutations("project", ["setSelectedProject"]),
            ...mapMutations("network", ["setSelectedNetwork"]),

            loadProject(){
                this.setAppBarTitle('loading')
                projectService.loadProject(this.$route.params.id)
                    .then(data => {
                        this.setSelectedProject(data)
                        this.setAppBarTitle(this.selectedProject.name)
                    })
                    .catch(error => {
                        this.setAppBarTitle(this.error)
                    })
            },

            loadNetworks(){
                this.loading = true
                networkService.loadMyNetwork(this.$route.params.id)
                    .then(data => {
                        this.networks = data
                        this.loading = false
                    })
                    .catch(error => {
                        this.error = error
                        this.loading = false
                    })
            },

            goToNetwork(n){
                this.setSelectedNetwork(n)
                this.$router.push(`/project/${this.selectedProject.id}/network/${n.id}`)
            }
        }
    }
</script>